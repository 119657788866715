import type { WatchHandle } from 'vue'
import {
  type INode,
  resolveVariable,
  useManagerFormValue,
  usePathResolution,
} from '@manager'

export const useDataDependentOn = (
  node: INode,
  parentPath: string | undefined,
  parentValue: Ref<any>,
) => {
  const { getRelativePathById } = usePathResolution(parentPath)
  const { getProperty: _getProperty } = useManagerFormValue()

  const getProperty = (dependentOn: number | null | undefined) => {
    const path = getRelativePathById(dependentOn)
    if (path) return _getProperty(resolveVariable(path, 'data'))
  }

  if (node.dataDependentOn) {
    let stop: WatchHandle
    tryOnMounted(() => {
      stop = watch(
        () => getProperty(node.dataDependentOn),
        (dataDependentOn) => {
          parentValue.value[node.name] = dataDependentOn ?? null
        },
        { deep: true },
      )
    })
    tryOnScopeDispose(() => {
      stop?.()
    })
  }
}
